// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$header: rgba(255,255,255,0.9);
$background: #fff;
$footer: transparent;

$color1: #19BB9B;
$color2: #375963;
$color3: #D1C19A;
$color4: #4B6A73;
$color5: #E5E5E5;
$color6: #EBEBEB;
$color7: #0D0D0D;
$color8: #707070;
$color9: #FCFCFC;
$color10: #F2F2F2;
$color11: #21AEFE;
$color12: #F6F6F6;
$color13: #B7B7B7;
$color14: #385A63;
$color15: #362424;
$color16: #F4F4F4;
$color17: #DBDBDB;
$color18: #6C6C6C;
$color19: #F1ECE0;
$color20: #5F7A82;
$color21: #FAFAFA;

$white: #fff;
$black: #000;

$text--dark: #7D7D7D;
$text--light: #898989;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.color1 {
	color: $color1;
}

.color2 {
	color: $color2;
}

.color3 {
	color: $color3;
}

.color4 {
	color: $color4;
}

.color5 {
	color: $color5;
}

.color6 {
	color: $color6;
}

.color7 {
	color: $color7;
}

.color8 {
	color: $color8;
}

.color9 {
	color: $color9;
}

.color10 {
	color: $color10;
}

.color11 {
	color: $color11;
}

.color12 {
	color: $color12;
}

.color13 {
	color: $color13;
}

.color14 {
	color: $color14;
}

.color15 {
	color: $color15;
}

.color16 {
	color: $color16;
}

.color17 {
	color: $color17;
}

.color18 {
	color: $color18;
}

.color19 {
	color: $color19;
}

.color20 {
	color: $color20;
}

.color21 {
	color: $color21;
}