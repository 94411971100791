// MEDIA QUERIES

/* max-width: 320px */
@mixin xs {
	@media(max-width: #{$small-phone-width}) {
		@content;
	}
}

/* max width: 320px and orientation: landscape */
@mixin xs-h {
	@media(max-width: #{$small-phone-width}) and (orientation: landscape) {
		@content;
	}
}

/* max-width: 320px and orientation: portrait */
@mixin xs-v {
	@media(max-width: #{$small-phone-width}) and (orientation: portrait) {
		@content;
	}
}

/* max width: 575px and orientation: landscape */
@mixin horizontal {
	@media(max-width: 575px) and (orientation: landscape) {
		@content;
	}
}

/* max width: 575px and orientation: portrait */
@mixin vertical {
	@media(max-width: 575px) and (orientation: portrait) {
		@content;
	}
}

/* min-width: 576px */
@mixin sm {
  	@media (min-width: #{$phone-width}) {
    	@content;
  	}
}

/* min-width: 576px and orientation: landscape */
@mixin sm-h {
  	@media (min-width: #{$phone-width}) and (orientation: landscape) {
    	@content;
  	}
}

/* min-width: 576px and orientation: portrait */
@mixin sm-v {
  	@media (min-width: #{$phone-width}) and (orientation: portrait) {
    	@content;
  	}
}

/* min-width: 768px */
@mixin md {
  	@media (min-width: #{$tablet-width}) {
    	@content;
  	}
}

/* min-width: 768px and orientation: landscape */
@mixin md-h {
  	@media (min-width: #{$tablet-width}) and (orientation: landscape) {
    	@content;
  	}
}

/* min-width: 768px and orientation: portrait */
@mixin md-v {
  	@media (min-width: #{$tablet-width}) and (orientation: portrait) {
    	@content;
  	}
}

/* min-width: 992px */
@mixin lg {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

/* min-width: 992px and orientation: landscape */
@mixin lg-h {
	@media (min-width: #{$desktop-width}) and (orientation: landscape) {
		@content;
	}
}

/* min-width: 992px and orientation: portrait */
@mixin lg-v {
	@media (min-width: #{$desktop-width}) and (orientation: portrait) {
		@content;
	}
}

/* min-width: 1200px */
@mixin xl {
	@media (min-width: #{$large-desktop-width}) {
		@content;
	}
}

/* min-width: 1200px and orientation: landscape */
@mixin xl-h {
	@media (min-width: #{$large-desktop-width}) and (orientation: landscape) {
		@content;
	}
}

/* min-width: 1200px and orientation: portrait */
@mixin xl-v {
	@media (min-width: #{$large-desktop-width}) and (orientation: portrait) {
		@content;
	}
}

/* min-width: 1366px */
@mixin xxl {
	@media (min-width: #{$xxlarge-desktop-width}) {
		@content;
	}
}

/* min-width: 1601px */
@mixin xxxl {
	@media (min-width: #{$xxxlarge-desktop-width}) {
		@content;
	}
}
// KONIEC MEDIA QUERIES

/* retina ready */
@mixin retina {
	@media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
		@content;
	}

	@media (-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 144dpi){ 
		@content;
	}

	@media (-webkit-min-device-pixel-ratio: 1.3),(min-resolution: 124.8dpi){ 
		@content;
	}
}

/* IE 10+ media query */
@mixin IE {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

/* media query for not IE */
@mixin non-IE {
	@supports not (-ms-high-contrast: none) {
		@content;
	}
}

/* Edge media query */
@mixin edge {
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

/* Firefox media query */
@mixin firefox {
	@-moz-document url-prefix() {
		@content;
	}
}

/* Safari 10.1+ media query */
@mixin safari {
	@media not all and (min-resolution:.001dpcm) { 
		@content;
	}
}

/* IE min-height fix */
@mixin IE-min-height($height) {
	&::after {
		content: "";
		height: $height;
		width: 0;
		visibility: hidden;
		overflow: hidden;
		visibility: hidden;
		float: left;
	}
}

/* display: inline-block */
@mixin inline-block {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

/* display: table */
@mixin table {
	display: table;
}

/* display: table-cell; */
@mixin table-cell($valign: middle) {
	display: table-cell;
	vertical-align: $valign;
}

/* display: flex-center */
@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* przezroczystość */
@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=$opacity*100);
}

/* gradient */
@mixin gradient($color1, $color2) {
	background: $color1; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzBlNzI5YyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxNzk0YTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(left,  $color1 0%, $color2 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,$color1), color-stop(100%,$color2)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  $color1 0%,$color2 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  $color1 0%,$color2 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  $color1 0%,$color2 100%); /* IE10+ */
	background: linear-gradient(to right,  $color1 0%,$color2 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); /* IE6-8 */
}

/* zaokrąglenie narożników */
@mixin border-radius($radius1: 49px, $radius2: 49px, $radius3: 49px, $radius4: 49px) {
	border-top-left-radius: $radius1;
	border-top-right-radius: $radius2;
	border-bottom-right-radius: $radius3;
	border-bottom-left-radius: $radius4;
}

/* animacja */
@mixin transition($prop: all, $time: 0.2s, $easing: ease-in-out, $delay: 0s) {
	transition: $prop $time $easing $delay;
}

/* cień tekstu */
@mixin text-shadow($x: 0.7px, $y: 0.7px, $blur: 3px, $color: rgba(35, 31, 32, 0.5)) {
	text-shadow: $x $y $blur $color;   
}

/* cień obiektu */
@mixin box-shadow($x: 1px, $y: 1px, $blur: 1px, $spread: 1px, $color: rgba(35, 31, 32, 0.5)) {
	box-shadow: $x $y $blur $spread $color;   
}

/* centrowanie w pionie (do elemenu rodzica trzeba jeszcze dodać transform-style: preserve-3d;) */
@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/* background-image: cover */
@mixin background-cover($image, $position: center center) {
	background-image: url($image);
	background-repeat: no-repeat;
	background-position: $position;
	background-size: cover;
}

/* odbicie obrazka */
@mixin flip($direction: FlipH) {
	transform: scaleX(-1);
	filter: $direction;
}

/* object fit cover */
@mixin object-fit-cover {
	object-fit: cover;
	font-family: 'object-fit: cover;';
}