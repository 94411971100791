// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$section__note: $color1;
$section__heading: $black;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.section {

	&__note {
		font: {
			size: 14px;
			weight: 600;
		}
		color: $section__note;
		letter-spacing: 0.18em;
		text-transform: uppercase;
		margin-bottom: 0.6rem;
	}
	
	&__heading {
		font: {
			size: 2.25rem;
			weight: 300;
		}
		margin-top: 0;
		margin-bottom: 1.75rem;
		color: $section__heading;
	}
}