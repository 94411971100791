// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$price__bgc: $color2;
$price__heading: $white;
$price__text: $white;

$price-cta__border: $color20;
$price-cta__heading: $white;
$price-cta__text: $white;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.price {
	background-color: $price__bgc;
	padding: 3.8rem 2.5rem 3.8rem 2.5rem;
	position: relative;

	@include md {
		padding: 4.3rem 3.5rem 15.8rem 3.5rem;
	}

	@include xl {
		background-size: contain;
		padding: 4.7rem 4.9rem 15.8rem 4.9rem;
	}

	.container {
		padding-left: 0;
		padding-right: 0;

		@include xxl {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__heading {
		font: {
			size: 1.875rem;
		}
		margin-top: 0.7rem;
		line-height: 1.27;
		color: $price__heading;
	}

	&__text {
		color: $price__text;
	}
}

.price-cta {
	border-top: solid 1px $price-cta__border;
	border-bottom: solid 1px $price-cta__border;
	padding-top: 1.6rem;
	padding-bottom: 1.55rem;
	margin-top: 2.2rem;

	&__heading {
		font: {
			size: 1.5rem;
			weight: 300;
		}
		color: $price-cta__heading;
		margin-bottom: 1rem;
	}

	&__text {
		font: {
			size: 14px;
		}
		color: $price-cta__text;

		&:last-child {
			margin-bottom: 0;
		}
	}
}