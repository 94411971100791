// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$footer__bgc: $footer;
$footer__menu__link: $black;
$footer__menu__link--hover: $color1;

$footer-address__label: $text--light;
$footer-address__link: $color1;
$footer-address__link--hover: $black;

$footer-copyright__text: $text--light;
$footer-copyright__border: $color5;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.footer {
	padding: 4rem 1rem 4.25rem 1rem;
	background-color: $footer__bgc;

	&__menu {
		font: {
			size: 13px;
			weight: 600;
		}

		li {
			margin-bottom: 0.82rem;
		}

		a {
			color: $footer__menu__link;
			text-decoration: none;

			&:hover {
				color: $footer__menu__link--hover;
			}
		}
	}
}

.footer-address {

	&__label {
		font: {
			size: 13px;
			weight: 600;
		}
		color: $footer-address__label;
		margin-bottom: 1.15rem;
	}

	a {
		display: block;
		font: {
			size: 1rem;
			weight: 600;
		}
		text-decoration: none;
		color: $footer-address__link;

		&:hover {
			color: $footer-address__link--hover;
		}
	}
}

.footer-copyright {
	margin-top: 5.65rem;
	padding-top: 1.85rem;
	position: relative;

	&::before {
		content: "";
		width: calc(100% - 30px);
		margin-left: auto;
		margin-right: auto;
		top: 0;
		position: absolute;
		background-color: $footer-copyright__border;
		height: 1px;
		z-index: 10;
	}

	&__text {
		font: {
			size: 13px;
			weight: 600;
		}
		color: $footer-copyright__text;
		
		@include md {
			margin-bottom: 0;
		}
	}

	.footer-copyright__back-to-top.btn {
		width: 3.125rem;
		height: 3.125rem;
		min-width: 0;
		border-radius: 50%;
		@include flex-center;
	}
}