// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$faq__accordion__bgc: transparent;
$faq__accordion__border: $color5;
$faq__accordion: $black;
$faq__accordion--hover: $color1;
$faq__accordion__after__bgc: $white;
$faq__accordion__after__border: $color6;
$faq__accordion__after: $color1;
$faq__accordion__after__bgc--hover: $color1;
$faq__accordion__after__border--hover: $color1;
$faq__accordion__after--hover: $white;
$faq__panel__bgc: transparent;
$faq__text: $text--light;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.faq {
	overflow: hidden;
	padding-bottom: 3rem;

	&__header {
		padding-top: 3.5rem;
		padding-bottom: 2.6rem;
	}

	&__content {
		border-bottom: solid 1px $faq__accordion__border;
		max-width: calc(100% - 30px);

		@include md {
			max-width: 83.333333%;
		}
	}

	&__accordion {
		position: relative;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		background-color: $faq__accordion__bgc;
		color: $faq__accordion;
		font: {
			size: 1rem;
			weight: 600;
		}
		cursor: pointer;
		padding: 0.8rem 4rem 0.8rem 0;
		width: 100%;
		text-align: left;
		text-decoration: none;
		border: none;
		border-top: solid 1px $faq__accordion__border;
		outline: none !important;
		box-shadow: none;
		@include transition;

		@include md {
			padding: 0.8rem 5rem 0.8rem 0;
		}

		@include xl {
			padding: 0.8rem 6rem 0.8rem 0;
		}

		&:first-child {
			border-top: none;
		}

		&::after {
			content: '\f078';
			position: absolute;
			right: 0;
			top: 0.6rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 1.875rem;
			height: 1.875rem;
			border-radius: 50%;
			border: solid 1px $faq__accordion__after__border;
			font: {
				family: "Font Awesome 5 Free";
				size: 0.9rem;
				weight: 700;
			}
			color: $faq__accordion__after;
			background-color: $faq__accordion__after__bgc;
			line-height: 0;
			z-index: 1;
			@include transition;

			@include md {
				top: 0.65rem;
			}
		}
	}

	.active,
	&__accordion:hover {
		color: $faq__accordion--hover;

		&::after {
			background-color: $faq__accordion__after__bgc--hover;
			color: $faq__accordion__after--hover;
			border: solid 1px $faq__accordion__after__border--hover;
		}
	}

	&__accordion:focus {
		color: $faq__accordion;

		&::after {
			background-color: $faq__accordion__after__bgc;
			color: $faq__accordion__after;
			border: solid 1px $faq__accordion__after__border;
		}
	}

	.active {
		color: $faq__accordion--hover;
		padding-bottom: 0.4rem;

		&::after {
			content: "\f077";
			background-color: $faq__accordion__after__bgc--hover;
			color: $faq__accordion__after--hover;
			border: solid 1px $faq__accordion__after__border--hover;
		}

		&+.faq__panel {
			margin-bottom: 0.9rem;
		}
	}

	&__panel {
		padding-right: 4rem;
		background-color: $faq__panel__bgc;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;

		@include md {
			padding-right: 5rem;
		}

		@include xl {
			padding-right: 6rem;
		}
	}

	&__text {
		font: {
			size: 13px;
		}
		color: $faq__text;
		line-height: 1.7;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}