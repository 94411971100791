.no-transform {
	text-transform: none;
}

.focus-only {
	position: absolute;
	clip: rect(0, 0, 0, 0);
}

.focus-only:focus {
	position: static;
}

#tresc:focus {
	outline: 0;
}

.sticky {
	position: sticky;
	top: 0;

	&::before,
	&::after {
		content: "";
		display: table;
	}
}

.screen-reader-text {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.ie-d-block {
	@include IE {
		display: block !important;
	}
}

.ie-d-flex {
	@include IE {
		display: flex !important;
	}
}

.separator {
	display: block;
	width: 100%;
}

/* Unyson no-padding */
.fw-page-builder-content .fw-container {
	padding: 0;
}