// -----------------------------//
//          VARIABLES           //
// -----------------------------//



// -----------------------------//
//            STYLES            //
// -----------------------------//

:root {
	--animate-duration: 1s;
	--animate-delay: 1s;
	--animate-repeat: 1;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-duration: var(--animate-duration);
	animation-duration: var(--animate-duration);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.repeat-1 {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-iteration-count: var(--animate-repeat);
	animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
	-webkit-animation-iteration-count: calc(1 * 2);
	animation-iteration-count: calc(1 * 2);
	-webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
	animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
	-webkit-animation-iteration-count: calc(1 * 3);
	animation-iteration-count: calc(1 * 3);
	-webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
	animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-delay: var(--animate-delay);
	animation-delay: var(--animate-delay);
}

.animated.delay-2s {
	-webkit-animation-delay: calc(1s * 2);
	animation-delay: calc(1s * 2);
	-webkit-animation-delay: calc(var(--animate-delay) * 2);
	animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
	-webkit-animation-delay: calc(1s * 3);
	animation-delay: calc(1s * 3);
	-webkit-animation-delay: calc(var(--animate-delay) * 3);
	animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
	-webkit-animation-delay: calc(1s * 4);
	animation-delay: calc(1s * 4);
	-webkit-animation-delay: calc(var(--animate-delay) * 4);
	animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
	-webkit-animation-delay: calc(1s * 5);
	animation-delay: calc(1s * 5);
	-webkit-animation-delay: calc(var(--animate-delay) * 5);
	animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
	-webkit-animation-duration: calc(1s / 2);
	animation-duration: calc(1s / 2);
	-webkit-animation-duration: calc(var(--animate-duration) / 2);
	animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
	-webkit-animation-duration: calc(1s * 0.8);
	animation-duration: calc(1s * 0.8);
	-webkit-animation-duration: calc(var(--animate-duration) * 0.8);
	animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
	-webkit-animation-duration: calc(1s * 2);
	animation-duration: calc(1s * 2);
	-webkit-animation-duration: calc(var(--animate-duration) * 2);
	animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
	-webkit-animation-duration: calc(1s * 3);
	animation-duration: calc(1s * 3);
	-webkit-animation-duration: calc(var(--animate-duration) * 3);
	animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
	.animated {
		-webkit-animation-duration: 1ms !important;
		animation-duration: 1ms !important;
		-webkit-transition-duration: 1ms !important;
		transition-duration: 1ms !important;
		-webkit-animation-iteration-count: 1 !important;
		animation-iteration-count: 1 !important;
	}

	.animated[class*='Out'] {
		opacity: 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadeIn {
	animation-name: fadeIn;
}