// -----------------------------//
//          VARIABLES           //
// -----------------------------//



// -----------------------------//
//            STYLES            //
// -----------------------------//

.functions {
	padding: 4.5rem 0 0.9rem 0;
	background-image: url('../img/functions-bg.png');
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;

	@include lg {
		background-size: auto;
	}

	&__small-text {
		font: {
			size: 14px;
		}
	}
}