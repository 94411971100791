// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$benefits__hr__bgc: $color5;

$benefits-cta__gratis: $text--light;

$benefits-item__icon-wrapper__bgc: $color3;
$benefits-item__icon-wrapper__border: $color19;
$benefits-item__heading: $black;
$benefits-item__text: $text--light;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.benefits {
	padding: 2.9rem 0;

	&__header {
		padding: 4.35rem 0 3.55rem 0;
	}

	&__hr {
		content: "";
		width: 10rem;
		height: 1px;
		background-color: $benefits__hr__bgc;
		position: absolute;
		top: 3.46875rem;
		border: none;

		&.right {
			left: calc(100% - 5.0625rem);
		}

		&.left {
			right: calc(100% - 5.0625rem);
		}
	}
}

.benefits-item {
	margin-bottom: 3.5rem;

	&__icon-wrapper {
		position: relative;
		width: 6.9375rem;
		height: 6.9375rem;
		border-radius: 50%;
		background-color: $benefits-item__icon-wrapper__bgc;
		border: solid 0.5rem $benefits-item__icon-wrapper__border;
		@include flex-center;	
	}

	&__header {
		padding: 1.5rem 0 1.1rem 0;
	}

	&__heading {
		font: {
			size: 1.125rem;
			weight: 600;
		}
		color: $benefits-item__heading;
		margin-bottom: 0;
	}

	&__text {
		font: {
			size: 13px;
		}
		color: $benefits-item__text;
		text-align: center;
		line-height: 1.7;
	}
}

.benefits-cta {
	padding-top: 1.55rem;

	&__btn {
		margin-top: 0.95rem;
	}

	&__gratis {
		font: {
			size: 13px;
			weight: 500;
		}
		color: $costs-cta__gratis;
		margin-top: 0.85rem;
	}
}