// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$guarantee__bgc: $color2;
$guarantee__heading: $white;
$guarantee__text: $white;

$guarantee-box__border: $color3;
$guarantee-box__text: $color3;

$guarantee-cta__gratis: $white;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.guarantee {
	background-color: $guarantee__bgc;
	background-image: url('../img/guarantee-bg.png');
	background-size: auto 100%;
	background-position: bottom right;
	background-repeat: no-repeat;
	padding: 3.7rem 2.5rem 1.05rem 2.5rem;

	@include xl {
		background-size: contain;
	}

	&__header {
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}

	&__heading {
		color: $guarantee__heading;
		margin-bottom: 0.6rem;
	}

	&__icon {
		vertical-align: baseline;
	}

	&__text {
		color: $guarantee__text;
		text-align: center;

		&--small {
			font: {
				size: 15px;
			}
			line-height: 1.5;
			margin-bottom: 1.4rem;
		}
	}

	&-box {
		border-bottom: solid 3px $guarantee-box__border;
		min-height: 4.45rem;
		margin-bottom: 30px;

		&__text {
			font: {
				size: 1.125rem;
				weight: 600;
			}
			color: $guarantee-box__text;
			margin-bottom: 0;
		}
	}

	&__why {
		padding-top: 1.55rem;
		padding-bottom: 0.3rem;
	}
}

.guarantee-cta {

	&__btn {
		margin-top: 0.95rem;
	}

	&__gratis {
		font: {
			size: 13px;
			weight: 500;
		}
		color: $guarantee-cta__gratis;
		margin-top: 0.85rem;
	}
}