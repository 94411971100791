// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$price-list__bgc: $color16;

$price-list-table__bgc: $white;
$price-list-table__thead__border: $color5;
$price-list-table__heading: $black;
$price-list-table__del: $text--light;
$price-list-table__price: $color1;
$price-list-table__desc: $text--light;
$price-list-table__desc__border: $color5;
$price-list-table__td: $black;
$price-list-table__td--even: $color21;
$price-list-table__td__icon: $color3;
$price-list-table__cta__border: $color5;
$price-list-table--popular__border: $color3;
$price-list-table__label__text: $white;

$tooltip__bgc: $white;
$tooltip__text: $text--light;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.price-list {
  background-color: $price-list__bgc;
  padding-top: 3rem;
  padding-bottom: 0.8rem;

  @include md {
    padding-top: 0;
  }

  .container-fluid {
    max-width: 1140px;
  }
}

.price-list-table {
  background-color: $price-list-table__bgc;
  margin-bottom: 3rem;
  border-radius: 0.7rem;
  width: 100%;

  @include md {
    margin-top: -154px;
  }

  @include xl {
    margin-top: -173px;
  }

  &--popular {
    box-shadow: inset 0px 0px 0px 3px $price-list-table--popular__border;

    .price-list-table__th {
      position: relative;
    }

    .price-list-table__label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $price-list-table--popular__border;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      font: {
        size: 14px;
        weight: 600;
      }
      color: $price-list-table__label__text;
      padding: 0.25rem 0 0.3rem 0;
    }

    .price-list-table__desc {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        top: -1px;
        left: 0;
        bottom: -1px;
        background-color: $price-list-table--popular__border;
        z-index: 10;

        @include md {
          width: 3px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 2px;
        top: -1px;
        right: 0;
        bottom: -1px;
        background-color: $price-list-table--popular__border;
        z-index: 10;

        @include md {
          width: 3px;
        }
      }
    }

    .price-list-table__td {
      border-left: solid 3px $price-list-table--popular__border;
      border-right: solid 3px $price-list-table--popular__border;
    }

    .price-list-table__cta {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        top: -1px;
        left: 0;
        height: 3px;
        background-color: $price-list-table--popular__border;
        z-index: 10;

        @include md {
          width: 3px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 2px;
        top: -1px;
        right: 0;
        height: 3px;
        background-color: $price-list-table--popular__border;
        z-index: 10;

        @include md {
          width: 3px;
        }
      }
    }
  }

  &__thead {
    border-bottom: solid 1px $price-list-table__thead__border;
  }

  &__th {
    padding: 3.3rem 1rem 2.35rem 1rem;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
  }

  &__heading {
    font: {
      size: 1.5rem;
      weight: 600;
    }
    color: $price-list-table__heading;
    margin-bottom: 0.1rem;
  }

  &__del {
    font: {
      size: 1.125rem;
      weight: 300;
    }
    color: $price-list-table__del;
    margin-right: 0.5rem;
  }

  &__price {
    transition: all 0.3s ease-in;
    display: inline-block;
    transform: translatex(-50px);
    opacity: 0;

    font: {
      size: 1.875rem;
      weight: 400;
    }
    color: $price-list-table__price;
  }

  &__desc {
    font: {
      size: 13px;
    }
    color: $price-list-table__desc;
    line-height: 1.7;
    text-align: center;
    padding: 1.5rem;
    border-bottom: solid 1px $price-list-table__desc__border;

    @include md {
      padding: 1.5rem 2rem 1.4rem 2rem;
    }

    @include xl {
      padding: 2rem 3rem 1.45rem 3rem;
    }
  }

  &__tbody {
    tr:nth-of-type(even) {
      .price-list-table__td {
        background-color: $price-list-table__td--even;
      }
    }
  }

  &__td {
    font: {
      size: 14px;
    }
    color: $price-list-table__td;
    padding: 10px 1.1rem 10px 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md {
      padding: 0.6rem 1.1rem 0.6rem 2rem;
    }

    @include xl {
      padding: 0.6rem 1.1rem 0.6rem 3rem;
    }

    .far,
    .fas {
      margin-right: 0.4rem;
      color: $price-list-table__td__icon;
    }
  }

  &__button {
    background: none;
    border: none;
    width: 18px;
    height: 18px;
    padding: 0;
  }

  &__cta {
    border-top: solid 1px $price-list-table__cta__border;
    padding: 1.9rem 1.65rem;
  }
}

.tooltip .arrow {
  display: none;
}

.tooltip-inner {
  background-color: $tooltip__bgc;
  font: {
    size: 13px;
  }
  color: $tooltip__text;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  @include box-shadow($y: 4px, $blur: 20px, $color: rgba(0, 0, 0, 0.2));
}

.slide-in {
  transform: translatey(0px);
  opacity: 1;
}
