// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$banner-item__border: $background;
$banner__heading: $white;
$banner__desc: $white;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.banner {
	overflow: hidden;
	min-height: 0;
	position: relative;
	z-index: 100;

	&__content {
		top: 0;
	}

	&__h1 {
		color: #fff;
		font-weight: 600;
		font-size: 2.5rem;
	}

	&__picture {
		filter: brightness(75%);

		img {
			@media(max-width: 767px) {
				min-height: 660px;
				object-fit: cover;
			}
		}
	}

	&__heading {
		font: {
			size: 6vw;
			weight: 400;
		}
		color: $banner__heading;
		line-height: 1.2;	
		margin-top: 0.55em;
		margin-bottom: 0.55em;

		@include md {
			font: {
				size: 4.5vw;
			}
			margin-top: 0;
		}

		@include xl {
			font: {
				size: 3vw;
			}
		}

		@include xxxl {
			font: {
				size: 3rem;
			}
		}
	}

	&__desc {
		width: 100%;
		font: {
			size: 1.2rem;
		}
		color: $banner__desc;
		line-height: 1.35;
		max-width: 600px;

		@include md {
			font: {
				size: 1.35rem;
			}
		}

		@include lg {
			font: {
				size: 1.5rem;
			}
		}
	}

	&__trial {
		font: {
			size: 13px;
			weight: 500;
		}
		color: $banner__desc;

		.far {
			font: {
				size: 1.15em;
			}
			margin-right: 0.6em;
		}
	}
}