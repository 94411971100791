// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$advantages__bgc: $color2;
$advantages__heading: $white;
$advantages__text: $white;
$advantages__border: $color4;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.advantages {
	background-color: $advantages__bgc;
	position: relative;
	padding: 1rem;
	z-index: 100;

	@include lg {
		margin-top: -3.15rem;
	}

	&__col {
		border-bottom: solid 1px $advantages__border;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		&:last-child {
			border-bottom: none;
		}

		@include lg {
			border-bottom: none;
			border-right: solid 1px $advantages__border;
			padding-top: 0.87rem;
			padding-bottom: 0.87rem;

			&:last-child {
				border-right: none;
			}
		}
		
	}

	&__heading {
		font: {
			size: 1rem;
			weight: 600;
		}
		color: $advantages__heading;
		width: 100%;
		margin-top: 0.65rem;
		margin-bottom: 0.15rem;
	}

	&__text {
		font: {
			size: 13px;
		}
		color: $advantages__text;
		width: 100%;
		margin-bottom: 0;
	}
}