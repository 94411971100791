// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$reviews__bgc: $color9;

$reviews-testimonials__arrow__border: $color6;
$reviews-testimonials__arrow__before: $color1;
$reviews-testimonials__nav__img--active__border: $color3;
$reviews-testimonials__nav__author: $black;

$reviews-video-carousel__item__bgc: $black;
$reviews-video-carousel__slick-dot--active: $color1;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.reviews {
	background-color: $reviews__bgc;

	&__header {
		padding-top: 4.7rem;
		padding-bottom: 0.3rem;
	}

	&__heading {
		font: {
			size: 1.875rem;
		}
	}
}

.reviews-testimonials {

	&__item {
		
		p {
			width: 100%;
			margin: 0 auto;

			@include md {
				width: 75%;
			}

			@include lg {
				width: 66%;
			}
		}
	}

	.slick-arrow {
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		border: solid 1px $reviews-testimonials__arrow__border;
		z-index: 10;
	}

	.slick-prev {
		left: 0;

		&::before {
			content: "\f053";
			font: {
				family: "Font Awesome 5 Free";
				size: 0.9rem;
    			weight: 900;
			}
			color: $reviews-testimonials__arrow__before;
		}
	}

	.slick-next {
		right: 0;

		&::before {
			content: "\f054";
			font: {
				family: "Font Awesome 5 Free";
				size: 0.9rem;
    			weight: 900;
			}
			color: $reviews-testimonials__arrow__before;
		}
	}

	&-nav {
		max-width: 555px;
		margin-top: 3rem;
		margin-bottom: 0.85rem;

		&__item {
			
			&.slick-slide {
				@include flex-center;
				flex-wrap: wrap;
			}

			.reviews-testimonials-nav__author {
				font: {
					size: 1.125rem;
					weight: 400;
				}
				color: $reviews-testimonials__nav__author;
				margin-bottom: 0;
			}

			.reviews-testimonials-nav__website {
				font: {
					size: 14px;
				}
			}

			.reviews-testimonials-nav__author,
			.reviews-testimonials-nav__website {
				width: 100%;
				opacity: 0;
				visibility: hidden;
				white-space: nowrap;
				@include transition;

				@include sm {
					width: auto;
				}
			}
		}

		&__img {
			border: solid 5px #fff;
			transform: scale(0.875);
			margin-bottom: 1.1rem;
			@include transition;
		}

		.slick-slide {
			opacity: 0.3;
			@include transition;

			&.slick-current {
				opacity: 1;
				@include transition;

				.reviews-testimonials-nav__author,
				.reviews-testimonials-nav__website {
					opacity: 1;
					visibility: visible;
				}

				.reviews-testimonials-nav__img {
					border-color: $reviews-testimonials__nav__img--active__border;
					transform: none;
				}
			}
		}
	}
}

.reviews-video {

	&__header {
		padding-top: 4.7rem;
		padding-bottom: 2.75rem;
	}

	&__heading {
		font: {
			size: 1.5rem;
		}
	}

	&-carousel {
		width: 160%;
		max-width: 2268px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		@include md {
			width: 100vw;
		}

		&.slick-slider {
			margin-bottom: 0;
		}

		&__item {
			border-left: solid 0.5rem $reviews__bgc;
			border-right: solid 0.5rem $reviews__bgc;
			background-color: $reviews-video-carousel__item__bgc;

			@include md {
				border-left-width: 0.9375rem;
				border-right-width: 0.9375rem;
			}

			&.slick-current {

				.reviews-video-carousel__img {					
					opacity: 1;
					@include transition;
				}
			}
		}

		&__img {
			width: 100%;
			height: auto;
			opacity: 0.3;
			position: relative;
			@include transition;
		}

		.slick-dots {
			position: relative;
			bottom: 0;
			padding: 2rem 1rem;

			button {

				&::before {
					font: {
						size: 12px;
					}
					opacity: 0.6;
					@include transition;
				}
			}

			.slick-active {

				button {

					&::before {
						opacity: 1;
						color: $reviews-video-carousel__slick-dot--active;
					}
				}
			}
		}
	}
}