$price-tabs-bg-light: #385a63;
$price-tabs-bg-light2: #3f6772;
$price-tabs-bg-dark: #29444b;
$price-tabs-title: #1abc9c;
$price-tabs-hover: #29c4a5;
$price-tabs-font: "Hind", sans-serif;
/* Set height of body and the document to 100% to enable "full page tabs" */

.price-tabs-section {
  font-family: $price-tabs-font;
}

.price-tabs {
  display: flex;
  flex-direction: column;
}

.price-tab-header {
  &-content {
    padding: 1rem;
    background-color: $price-tabs-bg-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
    .price-tab-header-title {
      color: $price-tabs-title;
      font-size: 14px;
      padding: 1rem;
    }
    .price-tab-header-title2 {
      color: white;
      font-size: 30px;
      padding-bottom: 1rem;
    }
    .price-tab-header-text {
      color: white;
      font-size: 18px;
      max-width: 900px;
      font-weight: 400;
      text-align: center;
    }
  }
  &-buttons {
    background-color: $price-tabs-bg-light;
  }
}
.price-tab-header-buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 75px;
  font-weight: bold;
  width: 100%;
}
.price-tabs-content {
  min-height: 400px;
  background: $price-tabs-bg-dark;
}

/* Style tab links */
.tab-button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $price-tabs-bg-light2;
  float: left;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
  font-family: $price-tabs-font;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  min-width: 150px;
  text-decoration: none;

  &--active {
    background-color: $price-tabs-bg-dark;
    color: $price-tabs-title;
    border-top: 1px solid $price-tabs-title;
  }
}

.tab-button:hover {
  background-color: #2b464d;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;

  &.active-tab {
    display: block;
  }
}

// WP IDEA GO

.price-tab-box2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  padding-bottom: 220px;
  &-container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  img {
    padding: 0px 20px;
  }
}

#ideabox {
  background-color: $price-tabs-bg-dark;
}
#ideago {
  background-color: $price-tabs-bg-dark;
}

.price-tab-box2-info-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 72px;
  &-text {
    padding: 1.5rem;
    font-weight: 500;
  }
}
.info-button-container {
  display: flex;
  background-color: $price-tabs-bg-light2;
  min-height: 50px;
  min-width: 245px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.info-button {
  color: white;
  border-radius: 5px;
  border: none;
  min-height: 40px;
  min-width: 115px;
  background-color: $price-tabs-bg-light2;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .price-tab-discount {
    margin-left: 5px;
    border-radius: 40px;
    display: inline-block;
    min-width: 45px;
    text-align: center;
    min-height: 20px;
    background-color: $price-tabs-bg-dark;
    padding-top: 1px;
  }
  &:hover {
    background-color: $price-tabs-hover;
  }
}
.year-button {
  margin-left: 5px;
}
.price-tab-box2-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
}
.price-tab-box2-info {
  display: flex;
  min-width: 350px;
  max-width: 350px;
  flex: 1 1 350px;
  padding: 1rem;

  .price-tab-box2-texts {
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .price-info-title {
      font-size: 16px;
      font-weight: bold;
    }
    .price-info-text {
      font-size: 13px;
    }
    span {
      display: inline-block;
    }
  }
}

.price-tab-info-border {
  border-left: 1px solid rgba(255, 255, 255, 0.104);
}

.info-button-active {
  background-color: $price-tabs-title;
}

.price-list-table__price small {
  color: #a4a4a4;
  font-size: 1.3rem;
  margin-left: 5px;
}

.wpideabox-text {
  padding: 0;
  font-size: 18px;
}

@media screen and (max-width: 1095px) {
  .price-tab-info-border {
    border: none;
  }
  .price-tab-box2-text {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .price-tab-box2-info-buttons {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .price-list > div {
    margin-top: -130px;
  }
}
