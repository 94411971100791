// -----------------------------//
//          VARIABLES           //
// -----------------------------//



// -----------------------------//
//            STYLES            //
// -----------------------------//

.payment {
	padding: 5rem 0;

	&__img {
		margin: 0.75rem;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}