.rebranding-modal {
  text-align: center;
  padding: 10px !important;

  img {
    max-width: 100%;
    max-height: 220px;
  }

  .modal-dialog {
    max-width: 1020px;
  }

  .modal-footer {
    border: 0;
    padding: 0;
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    margin: 0 0 15px;
    color: #5e5e5e;
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }

  &__small-title {
    margin: 0 auto 10px;
    padding: 2px 15px;
    font-weight: 700;
    color: #5e5e5e;
    background: #ffc80b;
  }

  &__text {
    max-width: 85%;
    margin: 0 auto 2rem;
    color: #444;
    line-height: 1.5;
  }

  button.btn.btn--1 {
    background-color: #ffc80b;
    color: #000;
    padding: 1.067em 1.4em 0.9em;
  }
}