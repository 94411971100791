// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$header__bgc: $header;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.header {
	background: $header__bgc;
	height: auto;
	top: 0;
	z-index: 999;
	transition: all 0.2s ease-in-out;
	opacity: 1;
	padding: 0;

	@include lg {
		padding: 5px 0;
	}

	@include xxl {
		padding: 21px 0;
	}

	&__container {
		z-index: 10;
	}

	&__logo {
		width: 100%;
		max-width: 110px;

		@include lg {
			max-width: 128px;
		}
	}
}