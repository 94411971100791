// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$heading--h1: $text--dark;
$heading--h2: $text--dark;
$heading--h3: $text--dark;
$heading--h4: $text--dark;
$heading--h5: $text--dark;
$heading--h6: $text--dark;
$paragraph: $text--dark;
$blockquote: $text--dark;
$blockquote__border: $text--dark;
$link: $color1;
$link--hover: $color1;

// -----------------------------//
//            STYLES            //
// -----------------------------//

@mixin paragraph {
	font: {
		family: 'Hind', sans-serif;
		size: 1.125rem;
		weight: 400;
	}
	color: $paragraph;
	line-height: 1.6;
	margin-top: 0;
	margin-bottom: 1.75rem;
}

@mixin link {
	color: $link;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		color: $link--hover;
		text-decoration: none;
	}
}

@mixin h1 {
	font: {
		family: 'Hind', sans-serif;
		size: 2.5rem;
		weight: 400;
	}
	text: {
		align: center;
	}
	color: $heading--h1;
	margin-bottom: 0.7em;

	@include md {
		font: {
			size: 3.1rem;
		}
	}

	@include xl {
		font: {
			size: 3.75rem;
		}
	}
}

@mixin h2 {
	font: {
		family: 'Hind', sans-serif;
		size: 2.2rem;
		weight: 400;
	}
	text: {
		align: left;
	}
	color: $heading--h2;
	margin-top: 1em;
	margin-bottom: 1.15em;
}

@mixin h3 {
	font: {
		family: 'Hind', sans-serif;
		size: 1.12rem;
		weight: 900;
	}
	text: {
		align: left;
	}
	color: $heading--h3;
	margin-bottom: 1.5em;
}

@mixin h4 {
	font: {
		family: 'Hind', sans-serif;
		size: 1.1rem;
		weight: 700;
	}
	text: {
		align: left;
	}
	color: $heading--h4;
	margin-bottom: 1.35em;
}

@mixin h5 {
	font: {
		family: 'Hind', sans-serif;
		size: 1rem;
		weight: 400;
	}
	text: {
		align: left;
	}
	color: $heading--h5;
	margin-bottom: 1.25em;
}

@mixin h6 {
	font: {
		family: 'Hind', sans-serif;
		size: 1rem;
		weight: 300;
	}
	text: {
		align: left;
	}
	color: $heading--h6;
	margin-bottom: 1.6em;
}

@mixin blockquote {
	border-left: solid 0.3rem $blockquote__border;
	width: calc(100% - 30px);
	padding: {
		top: 1rem;
		bottom: 1rem;
		left: 1.7rem;
	}
	margin: {
		top: 0;
		bottom: 3.4em;
	}
	position: relative;

	@include xl {
		width: 1140px;
	}
}

@mixin blockquote_p {
	font: {
		family: 'Hind', sans-serif;
		size: 1rem;
		weight: 400;
	}
	text: {
		align: left;
	}
	color: $blockquote;
	line-height: 1.75;

	&:last-child {
		margin-bottom: 0;
	}
}