// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$label: $text--light;
$input__border: $color6;
$input__bgc: $white;
$input__text: $text--dark;
$input--focus__border: $color3;
$placeholder: $text--light;

$checkbox__bgc: rgba(255, 255, 255, 0);
$checkbox--checked__bgc: $color5;
$checkbox__border: $color6;
$checkbox--checked__border: $color5;
$checkbox__after__bgc: transparent;
$checkbox--disabled__bgc: $color1;

$modal__bgc: rgba(0,0,0,0.5);
$modal__heading: $text--dark;
$modal__header__close: $text--dark;
$modal__header__close--hover: $color1;

// -----------------------------//
//            STYLES            //
// -----------------------------//

label {
	font: {
		size: 13px;
		weight: 400;
	}
	color: $label;
	width: 100%;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
textarea,
input.form-control,
textarea.form-control {
	appearance: none;
	width: 100%;
	min-height: 3rem;
	background-color: $input__bgc;
	border: solid 1px $input__border;
	border-radius: 0;
	padding: 0.75rem 1rem;
	margin-bottom: 1.25rem;
	font: {
		size: 14px;
		weight: 400;
	}
	color: $input__text;
	box-shadow: none !important;

	&:focus {
		border-color: $input--focus__border;
		outline: none;
	}

	&::placeholder {
		color: $placeholder;
	}
}

textarea,
textarea.form-control {
	height: 14rem;
	min-height: 14rem;

	@include md {
		height: 8.25rem;
		min-height: 8.25rem;
	}
}

.icheckbox {
	@include inline-block;
	width: 15px;
	height: 15px;
	min-width: 15px;
	background-color: $checkbox__bgc;
	border: solid 1px $checkbox__border;
	border-radius: 3px;
	position: absolute;
	left: 0;
	cursor: pointer;

	&.checked {
		background-color: $checkbox--checked__bgc;
		background-image: url("../img/checkbox.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 14px 14px;
		border-color: $checkbox--checked__border;
	}

	&::after {
		content: "";
		border: none;
		border-radius: inherit;
		background-color: $checkbox__after__bgc;
		opacity: 1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include transition($time: 0.15s);
	}

	&+.wpcf7-list-item-label,
	&+span {
		cursor: pointer;
		padding-left: 0.4rem;
		padding-right: 0;
		line-height: 1.3;
	}
}

.icheckbox.focus {
	outline: none;
	border: solid 1px $input--focus__border;
}

.icheckbox.checked {
	&::after {
		opacity: 0;
	}
}

.icheckbox.disabled {
	opacity: 0.65;
	cursor: not-allowed;
}

.wpcf7-not-valid-tip {
	font: {
		size: 14px;
	}
	margin-top: -10px;
	margin-bottom: 15px;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.sent .wpcf7-response-output {
	border-color: #0073aa;
	background-color: #0073aa;
	color: #fff;
	padding: 0.6rem 1rem;
	font: {
		size: 14px;
		weight: 600;
	}
}

.wpcf7 form.sent .wpcf7-response-output {
	border-color: #09b891;
	background-color: #09b891;
}

div.wpcf7 .ajax-loader {
	position: absolute;
}

.modal {
	background-color: $modal__bgc;
}

.modal-dialog {
	box-shadow: none;

	@include md {
		max-width: 550px;
	}

	@include lg {
		max-width: 670px;
	}

	.modal-header {
		border-bottom: none;
		align-items: center;
		padding: 2.1rem 1rem;

		.close {
			position: absolute;
			margin: 0;
			padding: 0;
			top: 0;
			right: 0;
			height: 45px;
			width: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			color: $modal__header__close;
			opacity: 1;
			overflow: hidden;

			@include lg {
				height: 55px;
				width: 55px;
			}

			&:hover,
			&:focus,
			&:active {
				color: $modal__header__close--hover;
				text-decoration: none;
			}

			span {
				overflow: hidden;
				font: {
					size: 2.5rem;
					weight: 600;
				}
			}
		}
	}

	.modal-content {
		box-shadow: none;
		padding: 0 2rem 2rem 2rem;
		border-radius: 0.4rem;
	}

	.modal__heading {
		margin: 0;
		max-width: 70vw;

		@include md {
			max-width: none;
		}
	}

	.modal-body {
		padding: 0;
	}
}