// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$body__bgc: $background;
$selection__bgc: $color1;
$selection__text: $white;
$hr__border: $color5;

// -----------------------------//
//            STYLES            //
// -----------------------------//

html {
	font-size: 80%;

	@include md {
		font-size: 90%;
	}

	@include xl {
		font-size: 100%; /* 1rem = 16px */
	}

	@include retina {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}
}

body {
	display: flex;
	flex-direction: column;
	background-color: $body__bgc;
	overflow-x: hidden;
	min-height: 100vh;
	position: relative;
	padding-top: 64px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	margin-bottom: 0;

	@include lg {
		padding-top: 67px;
	}

	@include xl {
		padding-top: 68px;
	}

	@include xxl {
		padding-top: 100px;
	}
}

p {
	@include paragraph;
}

h1 {
	@include h1;
}

h2 {
	@include h2;
}

h3 {
	@include h3;
}

h4 {
	@include h4;
}

h5 {
	@include h5;
}

h6 {
	@include h6;
}

::selection {
	background-color: $selection__bgc;
	color: $selection__text;
}

hr {
	border-top: solid 1px $hr__border;
	max-width: 1080px !important;
	margin: 0;
}

/* Bootstrap grid width overwrite */
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}

.content {
	flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.footer {
	flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

a,
button {
	@include transition($time: 0.15s, $easing: ease-in-out);
	@include link;
}

figure {
	margin: 0;
}

/* width options */
.main > *:not(.section) {
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.alignfull,
.section {
	max-width: none;
}

.aligncenter,
.alignwide {
	width: 100%;
	max-width: 1110px;
	margin-left: auto;
	margin-right: auto;
}

.alignwide {
	max-width: 1270px;
}

.main {
	@media (min-width: 1921px) {
		width: 1920px;
		margin: 0 auto;
	}
}