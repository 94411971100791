// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$costs__bgc: $color10;
$costs__wrapper__bgc: $white;

$costs-table__th: $color15;

$costs-table__td__fas: $color3;
$costs-table__td--1: $text--light;
$costs-table__td--2: $black;
$costs-table__td--3: $black;
$costs-table__td__border: $color6;
$costs-table__td--3__border-horizontal: $color17;
$costs-table__td--3__bgc: $color16;
$costs-table__cost: $color18;
$costs-table__cost--wpidea: $color1;
$costs-table__td--tfoot__border: $color3;
$costs-table__td--tfoot: $text--light;

$costs-cta__gratis: $text--light;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.costs {
	background-color: $costs__bgc;
	padding-top: 3rem;
	padding-bottom: 1.85rem;

	&__header {
		padding: 3.7rem 0 2.6rem 0;
	}

	&__wrapper {
		background-color: $costs__wrapper__bgc;
		padding: 2.2rem 0;
	}
}

.costs-table {

	td,
	th,
	thead th {
		border-width: 1px;
		border-color: $costs__wrapper__bgc;
		padding: 0.9rem 0;
		vertical-align: middle;
	}

	&__logo {
		width: 100%;
		max-width: 120px;
		margin-bottom: 0.2rem;
	}

	.costs-table__th {
		font: {
			size: 1rem;
			weight: 500;
		}
		color: $costs-table__th;

		&--1 {
			width: 37.5%;
			min-width: 130px;
		}

		&--2 {
			width: 30.5%;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		&--3 {
			width: 32%;
			background-color: $costs-table__td--3__bgc;
			border-bottom: solid 1px $costs-table__td--3__border-horizontal;
			border-left: solid 2px $costs-table__td__border;
			border-right: solid 2px $costs-table__td__border;
			border-top: solid 2px $costs-table__td__border;
			border-bottom: none;
			padding-top: 1.5rem;
		}
	}

	.costs-table__td,
	.costs-table__td--1,
	.costs-table__td--2,
	.costs-table__td--3 {
		font: {
			size: 14px;
			weight: 500;
		}
		border-color: $costs-table__td__border;
	}

	.costs-table__td--1 {
		color: $costs-table__td--1;
		width: 37.5%;
		min-width: 130px;

		.fas,
		.far {
			color: $costs-table__td__fas;
		}
	}

	.costs-table__td--2 {
		width: 30.5%;
		color: $costs-table__td--2;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.costs-table__td--3 {
		width: 32%;
		background-color: $costs-table__td--3__bgc;
		font: {
			weight: 600;
		}
		color: $costs-table__td--3;
		border-bottom: solid 1px $costs-table__td--3__border-horizontal;
		border-left: solid 2px $costs-table__td__border;
		border-right: solid 2px $costs-table__td__border;
		border-top: none;
		padding-left: 2rem;
		padding-right: 2rem;
		vertical-align: middle;
	}

	.costs-table__th--3 {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&__cost {
		font: {
			size: 1.5rem;
			weight: 600;
		}
		color: $costs-table__cost;

		&.costs-table__cost--wpidea {
			color: $costs-table__cost--wpidea;
		}
	}

	tbody {

		tr:last-child {

			td {
				padding-bottom: 2.1rem;
			}
		}
	}

	tfoot {

		.costs-table__td {
			border-top: solid 3px $costs-table__td--tfoot__border;
			padding-top: 2rem;
			padding-bottom: 2rem;
			color: $costs-table__td--tfoot;

			&--3 {
				position: relative;
				border-bottom: solid 2px $costs-table__td__border;

				&::before {
					content: "";
					display: block;
					width: 3px;
					height: 3px;
					background-color: $costs-table__td--tfoot__border;
					position: absolute;
					top: -3px;
					left: -2px;
					z-index: 10;
				}

				&::after {
					content: "";
					display: block;
					width: 3px;
					height: 3px;
					background-color: $costs-table__td--tfoot__border;
					position: absolute;
					top: -3px;
					right: -2px;
					z-index: 10;
				}
			}
		}
	}
}

.costs-cta {
	padding-top: 2.8rem;

	&__btn {
		margin-top: 0.95rem;
	}

	&__gratis {
		font: {
			size: 13px;
			weight: 500;
		}
		color: $costs-cta__gratis;
		margin-top: 0.85rem;
	}
}