// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$knowledge__heading: $black;
$knowledge__text-box__bgc: $white;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.knowledge {
	background-image: url('../img/knowledge-bg.jpg');
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
	padding: 3rem 0 0 0;

	@include md {
		padding: 3.1rem 0;
		background-size: cover;
		background-position: center center;
	}

	&__text-box {
		background-color: $knowledge__text-box__bgc;
		width: 100%;
		padding: 2.5rem;
		margin-top: 34vw;

		@include md {
			width: 55%;
			max-width: 713px;
			margin-top: 0;
			padding: 3rem;
			margin-left: 2vw;
		}

		@include lg {
			margin-left: 5vw;
		}

		@include xl {
			width: 50%;
			margin-left: 11.7vw;
			padding: 4.5rem 4.8rem 2.95rem 4.8rem;
		}

		@include xxl {
			width: 46%;
		}

		@include xxxl {
			width: 43.2%;
		}
	}

	&__heading {
		font: {
			size: 1.5rem;
			weight: 300;
		}
		color: $knowledge__heading;
		line-height: 1.35;
		margin-top: 0;
		margin-bottom: 2rem;
	}
}