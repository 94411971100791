// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$intro__bgc--mobile: $color9;
$intro__bgc--desktop: $white;
$intro__heading: $black;
$intro__gratis: $color8;

$intro-box__bgc: $white;
$intro-box__border: $color6;
$intro-box__heading: $black;
$intro-box__text: $text--dark;
$intro-box__link: $color7;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.intro {
	padding: 5.8rem 0 4.6rem 0;
	background-image: linear-gradient(to bottom, #ffffff 25%,#fcfcfc 50%), url('../img/intro-bg.png');
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fcfcfc',GradientType=0 );
	background-position: bottom center;
	background-size: contain;
	background-repeat: no-repeat;
	background-blend-mode: multiply;

	@include IE {
		background-image: linear-gradient(to bottom, #ffffff 0%,#fcfcfc 60%);
	}

	@include md {
		background-color: $intro__bgc--desktop;
	}

	@include lg {

		@include IE {
			background-image: url('../img/intro-bg.png');
			background-size: cover;
		}
	}

	@include xxl {
		background-image: linear-gradient(to bottom, #ffffff 20%,#fcfcfc 45%) url('../img/intro-bg.png');

		@include IE {
			background-size: contain;
		}
	}

	@include xxxl {
		background-image: url('../img/intro-bg.png');
	}

	&__heading {
		font: {
			size: 1.5rem;
			weight: 300;
		}
		color: $intro__heading;
		line-height: 1.35;
	}

	&__text {
		margin-bottom: 1.75rem;
	}

	&__header {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	&__hr {
		margin: 3.9rem 0 0.6rem 0;
	}

	&__gratis {
		font: {
			size: 12px;
			weight: 500;
		}
		color: $intro__gratis;
	}
}

.intro-box {
	background-color: $intro-box__bgc;
	border: solid 2px $intro-box__border;
	width: calc(100% - 30px);
	padding: 3.15rem 2rem 2.75rem 2rem;
	position: relative;
	margin-bottom: 5rem;

	&::before {
		content: url('../img/intro-box-before.png');
		position: absolute;
		left: calc(50% + 22px);
		top: 20px;
		transform-origin: top right;
		transform: rotate(90deg);
	}

	&:first-child {

		&::before {
			display: none;
		}
	}

	&::after {
		content: url('../img/intro-box-after.png');
		position: absolute;
		right: calc(50% + 45px);
		bottom: -22px;
		transform-origin: bottom right;
		transform: rotate(90deg);
	}

	&:last-child {

		&::after {
			display: none;
		}
	}

	@include md {
		width: 48%;
		margin-bottom: 6%;

		&::before,
		&::after {
			display: none;
		}
	}

	@include lg {
		width: 31.5%;
		margin-bottom: 5%;

		&::before {
			transform: none;
			display: block;
			left: -2px;
			top: 4.6rem;
		}

		&::after {
			transform: none;
			display: block;
			right: -22px;
			top: 4.6rem;
			bottom: initial;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
	}

	&__heading {
		font: {
			size: 1rem;
			weight: 600;
		}
		color: $intro-box__heading;
		line-height: 1.4;
		margin-bottom: 1.1rem;
		width: 97%;
	}

	&__text {
		font: {
			size: 13px;
		}
		color: $intro-box__text;
		line-height: 1.7;
		margin-bottom: 1.2rem;
		width: 80%;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		font: {
			weight: 600;
		}
		color: $intro-box__link;
		text-decoration: none;
	}
}

.intro-how-it-works {

	&__heading {
		font: {
			size: 1.875rem;
		}
	}
}