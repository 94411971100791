// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$btn1__text: $white;
$btn1__text--hover: $white;
$btn1__bgc: $color1;
$btn1__bgc--hover: $color1;

$btn2__text: $white;
$btn2__text--hover: $white;
$btn2__border: $color1;
$btn2__border--hover: $color1;
$btn2__bgc: transparent;
$btn2__bgc--hover: transparent;
$btn2__far: $btn2__border;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.btn {
	font: {
		family: 'Hind', sans-serif;
		size: 15px;
		weight: 600;
	}
	text: {
		align: center;
		decoration: none;
	}
	line-height: 1.2;
	border-radius: 6px;
	margin: 0;
	min-width: 160px;
	box-shadow: none;
	position: relative;
	overflow: hidden;
	transition: all 0.3s;

	&::after {
		content: "";
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		right: 100%;
		border-radius: inherit;
		background-color: rgba(0, 0, 0, 0.15);
		transition: all 0.3s;
	}

	&::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		background-color: inherit;
		z-index: -1;
	}

	&:hover::after {
		right: 0;
	}

	&.btn--1 {
		background-color: $btn1__bgc;
		border: none;
		color: $btn1__text;
		padding: 1.067em 1.4em;
		border-radius: 5px;

		&:hover,
		&:focus,
		&:active {
			background-color: $btn1__bgc--hover;
			border: none;
			color: $btn1__text--hover;
			box-shadow: none;
		}
	}

	&.btn--2 {
		background-color: $btn2__bgc;
		border: solid 2px $btn2__border;
		color: $btn2__text;
		padding: 0.934em 1.4em;
		border-radius: 5px;

		&:hover,
		&:focus,
		&:active {
			background-color: $btn2__bgc--hover;
			border: solid 2px $btn2__border--hover;
			color: $btn2__text--hover;
			box-shadow: none;
		}

		.far {
			color: $btn2__far;
		}
	}

	&.header__cta {
		min-width: 0;
		padding: 1.067em 1.1em;

		@include xxl {
			padding: 1.067em 1.4em;
		}

		@include xxxl {
			min-width: 183px;
		}
	}

	&.section1__cta {

		@include xxl {
			min-width: 180px;
		}
	}
}

/* CTA button arrows */

.btn-arrows{
	position: relative;

	&::after{
		content: url('../img/arrow3.svg');
		position: absolute;
		top: -62px;
		right: -209px;
		width: 100%;
		height: auto;
	}
	&::before{
		content: url('../img/arrow1.svg');
		position: absolute;
		top: -34px;
		left: -205px;
		width: 100%;
		height: auto;
	}
}

.btn-index-h{
	z-index: 9;
}