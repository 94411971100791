// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$additional-functions-box__bgc: $color12;
$additional-functions-box__border: $color6;
$additional-functions-box__version: $text--light;
$additional-functions-box__heading: $black;
$additional-functions-box__overlay__bgc: $color3;
$additional-functions-box__overlay-title: $white;
$additional-functions-box__overlay-text: $white;

$additional-functions-cta__text: $text--light;
$additional-functions-cta__gratis: $text--light;

$additional-functions-trial__bgc: $color2;
$additional-functions-trial__heading: $white;
$additional-functions-trial__text: $white;

$additional-functions-trial__box__bgc: $color3;
$additional-functions-trial-box__text: $color14;
$additional-functions-trial__hr__border: $white;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.additional-functions {

	&__header {
		padding: 4.75rem 0 2.5rem 0;
	}
}

.additional-functions-box {
	position: relative;
	min-height: 323px;
	background-color: $additional-functions-box__bgc;
	border: solid 2px $additional-functions-box__border;
	margin-bottom: 30px;

	&__row {
		width: 100%;
		height: 107px;
	}

	&__version {
		font: {
			size: 10px;
			weight: 600;
		}
		color: $additional-functions-box__version;
		letter-spacing: 0.1em;
	}

	&__heading {
		font: {
			size: 1.125rem;
			weight: 600;
		}
		color: $additional-functions-box__heading;
		margin-top: 1.35rem;
	}

	&__icon {
		margin-bottom: 1.9rem;
	}

	&__overlay {
		position: absolute;
		background-color: $additional-functions-box__overlay__bgc;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: scale(0.75);
		opacity: 0;
		@include transition($time: 0.5s);

		&-title {
			font: {
				size: 1.125rem;
				weight: 600;
			}
			color: $additional-functions-box__overlay-title;
			margin-bottom: 0.9rem;
		}

		&-text {
			font: {
				size: 14px;
				weight: 500;
			}
			color: $additional-functions-box__overlay-text;
			line-height: 1.3;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&:hover {

		.additional-functions-box__overlay {
			opacity: 1;
			transform: none;
		}
	}
}

.additional-functions-cta {
	padding-top: 2.9rem;

	&__heading {
		font: {
			size: 1.5rem;
		}
		margin-bottom: 1rem;
	}

	&__text {
		font: {
			size: 15px;
		}
		color: $additional-functions-cta__text;
		line-height: 1.5;
	}

	&__btn {
		margin-top: 0.95rem;
	}

	&__gratis {
		font: {
			size: 13px;
			weight: 500;
		}
		color: $additional-functions-cta__gratis;
		margin-top: 0.8rem;
	}
}

.additional-functions-trial {
	background-color: $additional-functions-trial__bgc;
	padding: 4rem 2.5rem 2.4rem 2.5rem;
	background-image: url('../img/additional-functions-trial-bg.png');
	background-size: auto 100%;
	background-position: bottom left;
	background-repeat: no-repeat;
	position: relative;

	@include md {
		padding: 5rem 3.5rem 2.4rem 3.5rem;
	}

	@include xl {
		background-size: contain;
		padding: 5.85rem 4.9rem 2.4rem 4.9rem;
	}

	@include xxl {
		margin-bottom: -12rem;
	}

	&__heading {
		font: {
			size: 1.5rem;
		}
		margin-top: 0.9rem;
		color: $additional-functions-trial__heading;
	}

	&__text {
		color: $additional-functions-trial__text;
	}

	&-box {
		background-color: $additional-functions-trial__box__bgc;
		min-height: 6.875rem;
		margin-bottom: 30px;

		&__text {
			font: {
				size: 1.125rem;
				weight: 700;
			}
			color: $additional-functions-trial-box__text;
			margin-bottom: 0;
		}
	}

	hr {
		width: 100%;
		max-width: none !important;
		border-color: $additional-functions-trial__hr__border;
		opacity: 0.2;
		margin-bottom: 25px;
	}
}