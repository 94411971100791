// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$why__bgc: $color10;
$why__wrapper__bgc: $white;
$why__figcaption__bgc: $color10;
$why__figcaption__text: $text--light;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.why {
	background-color: $why__bgc;
	
	@include xxl {
		padding-top: 13.9rem;
	}

	&__wrapper {
		background-color: $why__wrapper__bgc;
		padding-top: 3.5rem;

		@include md {
			padding-top: 4rem;
		}

		@include xl {
			padding-top: 4.5rem;
		}
	}

	&__heading {
		font: {
			size: 1.5rem;
		}
		margin-top: 0.85rem;
	}

	&__content {
		padding-top: 2rem;
	}

	&__chart-wrapper {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+50,f2f2f2+50 */
		background: $why__wrapper__bgc; /* Old browsers */
		background: -moz-linear-gradient(top,  $why__wrapper__bgc 50%, $why__bgc 50%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  $why__wrapper__bgc 50%,$why__bgc 50%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  $why__wrapper__bgc 50%,$why__bgc 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$why__wrapper__bgc', endColorstr='$why__bgc',GradientType=0 ); /* IE6-9 */
	}

	&__figcaption {
		background-color: $why__figcaption__bgc;
		font: {
			size: 14px;
		}
		color: $why__figcaption__text;
		padding: 1.5rem;
	}
}