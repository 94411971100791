// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$contact__heading: $white;
$contact__hr__bgc: $white;
$contact__icon-wrapper__bgc: $color3;
$contact__link: $white;
$contact__text: $white;
$contact__form-wrapper__bgc: $white;
$contact__form-heading: $text--light;
$contact-form__checkbox-label: $color13;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.contact {
	background-image: url('../img/contact-bg.jpg');
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 280%;
	padding-top: 3.1rem;
	padding-bottom: 3.1rem;

	@include sm {
		background-size: cover;
	}

	&__heading {
		font: {
			size: 1.5rem;
			weight: 300;
		}
		color: $contact__heading;
		margin-top: 1.3rem;
		margin-bottom: 2.6rem;
	}

	&__hr {
		background-color: $contact__hr__bgc;
		opacity: 0.5;
		width: 100%;
		margin: 1.25rem 0;

		@include md {
			max-width: 80%;
		}
	}

	&__link {
		font: {
			family: "Hind", sans-serif;
			size: 1.125rem;
			weight: 600;
		}
		color: $contact__link;
		text-decoration: none;
		margin-bottom: 1.25rem;

		&:hover,
		&:focus,
		&:active {
			color: $contact__link;
		}
	}

	&__icon-wrapper {
		width: 3.75rem;
		height: 3.75rem;
		@include flex-center;
		background-color: $contact__icon-wrapper__bgc;
	}

	&__text {
		font: {
			size: 1rem;
		}
		color: $contact__text;
		line-height: 1.4;
	}

	&__form-wrapper {
		background-color: $contact__form-wrapper__bgc;
		padding: 2rem;
		width: 100%;

		@include md {
			padding: 3rem;
		}

		@include lg {
			width: 107%;
			padding: 2rem;
		}

		@include xl {
			width: 112.5%;
			padding: 3.1rem 4.1rem 2.6rem 4.1rem;
		}
	}

	&__form-heading {
		font: {
			size: 14px;
			weight: 600;
		}
		color: $contact__form-heading;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		margin-bottom: 1.6rem;

		@include md {
			letter-spacing: 0.18em;
		}
	}

	&-form {

		&__checkbox-label {
			position: relative;
			padding-top: 1px;
			padding-left: 1.5rem;
			font: {
				size: 12px;
				weight: 400;
			}
			text-transform: none;
			color: $contact-form__checkbox-label;
			line-height: 1.4;
		}

		&__submit {
			margin-top: 2.45rem;
		}
	}

	&__call-schedule {
		border: 2px solid #fff;
		padding: 2px 5px;
		margin: 5px;
	}
}