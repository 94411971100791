.trial-modal {
  @media (max-width: 575px) {
    padding: 10px !important;
  }

  .modal-dialog {
    @media (min-width: 1200px) {
      max-width: 1100px;
    }

    .modal-content {
      padding: 50px 60px 30px;

      @media (max-width: 1200px) {
        padding: 30px;
      }

      @media (max-width: 768px) {
        padding-bottom: 0;
      }
    }
  }

  &__small-title {
    color: $color1;
    line-height: 1.1em;
    letter-spacing: 3px;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0 15px;
  }

  &__title {
    color: #000;
    font-size: 30px;
    line-height: 0.8em;
    font-weight: 300;
    margin: 10px 0 15px;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
      line-height: 1.2;
    }
  }

  &__text {
    font-size: 16px;
    color: #696969;
    width: 100%;
  }

  label {
    text-align: left;
    color: #696969;
    font-weight: 600;
  }

  input.form-control,
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  textarea,
  textarea.form-control {
    border: 1px solid #cecece;
  }

  select {
    width: 100%;
    min-height: 3rem;
    margin-bottom: 10px;
    padding: 0.75rem 1rem;
    border-radius: 0;
    box-shadow: none !important;
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #cecece;
  }

  button[type="submit"] {
    width: 100%;
    margin-top: 30px;
  }

  .modal-footer {
    padding: 0;
    margin-bottom: 30px;
    border: 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .form-check {
    display: flex;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 768px) {
      align-items: flex-start;
      padding-left: 25px;
    }

    label {
      font-weight: normal;
    }
  }

  .icheckbox {
    border: 1px solid #cecece;
    margin-top: 3px;
    @media (max-width: 768px) {
      margin-top: 3px;
    }
  }

  .button-close-form {
    width: 210px;
    border: 0;
    background: no-repeat;
    margin: 20px 0 0;
    color: #4e4e4e;
    text-decoration: none;
  }
}
