// -----------------------------//
//          VARIABLES           //
// -----------------------------//

$major-functions__bgc: $color10;

$major-functions-box__bgc: $color6;

$major-functions-payment__bgc: $white;
$major-functions-payment__arrow__border: $color6;
$major-functions-payment__arrow__fas: $color11;

// -----------------------------//
//            STYLES            //
// -----------------------------//

.major-functions {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f2f2f2+100 */
	background: $color10; /* Old browsers */
	background: -moz-linear-gradient(top,  #ffffff 0%, $color10 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #ffffff 0%, $color10 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #ffffff 0%, $color10 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='$color10',GradientType=0 ); /* IE6-9 */
	padding-bottom: 3.7rem;
	
	&__header {
		padding-top: 4.65rem;
		padding-bottom: 2.8rem;
	}
}

.major-functions-box {
	background-color: $major-functions-box__bgc;
	padding: 4.75rem 2.5rem 2.5rem 2.5rem;

	@include md {
		padding: 4.75rem 3rem 3rem 3rem;
	}

	&__icon {
		top: 0;
		right: 0;
		z-index: 10;
	}

	&__heading {
		font: {
			size: 1.5rem;
		}
		margin-bottom: 1.5rem;
	}
}

.major-functions-payment {
	background-color: $major-functions-payment__bgc;
	padding: 4.75rem 2.5rem 2.5rem 2.5rem;
	
	@include md {
		margin-top: 0.375rem;
		padding: 4.75rem 3rem 3rem 3rem;
	}

	&__heading {
		font: {
			size: 1.5rem;
		}
		margin-bottom: 1.1rem;
	}

	.slick-arrow {
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		border: solid 1px $major-functions-payment__arrow__border;
		z-index: 10;
		margin: 0 0.3rem;
		cursor: pointer;

		.fas {
			font: {
				size: 0.9rem;
			}
			color: $major-functions-payment__arrow__fas;
		}
	}

	&-carousel {
		div {
			min-height: 175px;
		}
	}
}